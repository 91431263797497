import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './style2.css';


import reportWebVitals from '@/reportWebVitals';
import App from "@/App";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>
);
reportWebVitals();
